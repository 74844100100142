import UseCSVButton from "@usecsv/vuejs";
import { Crisp } from "crisp-sdk-web";
import LogRocket from 'logrocket';
import LottieAnimation from 'lottie-web-vue';
import Vue from 'vue';
import App from './App.vue';
import './assets/css/tailwind.css';
import vuetify from './plugins/vuetify';
import router from './router';
import store from './store/index';

Vue.prototype.$theme = {primary: "#216fed", secondary: "#216fed", secondary2: "#7b0db6"}

// Logrocket
LogRocket.init('xdt4dz/visum');
Crisp.configure(process.env.VUE_APP_CRISP_WEBSITE_ID, {
  autoload: false
});
Vue.use(LottieAnimation);
Vue.use(UseCSVButton);

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  store,
  render: h => h(App)
}).$mount('#app')

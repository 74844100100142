import AuthServices from "@/services/AuthServices";
import { Crisp } from "crisp-sdk-web";
import LogRocket from "logrocket";
import { Magic } from "magic-sdk";
import Swal from "sweetalert2";
import Vue from "vue";
import Vuex from "vuex";

const handle = (promise) => {
  return promise
    .then((data) => [data, undefined])
    .catch((error) => Promise.resolve([undefined, error]));
};
const plans = {
  [process.env.VUE_APP_STRIPE_PLAN_1_MONTHLY]: {
    title: "Basic",
    price: {
      monthly: 99,
    },
  },
  [process.env.VUE_APP_STRIPE_PLAN_2_MONTHLY]: {
    title: "Growth",
    price: {
      monthly: 299,
    },
  },
  [process.env.VUE_APP_STRIPE_PLAN_3_MONTHLY]: {
    title: "Enterprise",
    price: {
      monthly: 999,
    },
  },
};

Vue.use(Vuex);

const magic = new Magic(process.env.VUE_APP_MAGIC_KEY);

export default new Vuex.Store({
  state: {
    user: null,
  },
  mutations: {
    SET_USER_DATA(state, userData) {
      state.user = userData;
      LogRocket.identify(state.user._id, {
        name: state.user.firstName + " " + state.user.lastName,
        email: state.user.email,
      });

      Crisp.setTokenId(state.user._id);
      Crisp.load();

      Crisp.user.setEmail(state.user.email);
      Crisp.user.setNickname(state.user.firstName + " " + state.user.lastName);

      Crisp.session.setData({
        user_id: state.user._id,
        plan_title:
          state.user.stripe.priceId != "" &&
          state.user.stripe.priceId != undefined
            ? plans[state.user.stripe.priceId] != undefined
              ? plans[state.user.stripe.priceId].title
              : "other"
            : "free",
        plan_price:
          state.user.stripe.priceId != "" &&
          state.user.stripe.priceId != undefined
            ? plans[state.user.stripe.priceId] != undefined
              ? plans[state.user.stripe.priceId].monthly
              : "other"
            : 0,
        credits: state.user.quota.credits,
        credits_total: state.user.quota.total,
        rate_limit:
          state.user.quota.rate_limit + "/" + state.user.quota.total_rate_limit,
        credits_used: state.user.quota.totalUsed,
        total_match: state.user.quota.totalMatch,
        old_client:
          state.user.stripe.customerId != "" &&
          state.user.stripe.customerId != undefined,
        registered_on: state.user.created_at,
      });
      localStorage.setItem("user", JSON.stringify(state.user));
    },
    CLEAR_USER_DATA() {
      localStorage.removeItem("user");
      location.reload();
    },
  },
  actions: {
    async getOTP({ commit }, { email, captcha }) {
      try {
        await AuthServices.getOTP({
          email,
          captcha,
        });
      } catch (error) {
        return Promise.reject(error);
      }
    },
    async auth({ commit }, access_token) {
      try {
        const response = await AuthServices.auth({ access_token });
        if (response.status == 200) {
          const { user } = response.data;
          commit("SET_USER_DATA", user);
          window.location.href = "/search";
        }
      } catch (error) {
        window.location.href = "/login";
        Swal.fire({
          icon: "error",
          title: "Authentication Error",
          text: "Please contact support",
        });
      }
    },
    async logout({ commit }) {
      const response = await AuthServices.logout();
      console.log(response);
      if (response.status == 200) {
        commit("CLEAR_USER_DATA");
      }
    },
    async profile({ commit }) {
      const [response, responseError] = await handle(AuthServices.getProfile());

      if (responseError && responseError.response.status == 401) {
        commit("CLEAR_USER_DATA");
      }

      if (response.status == 200) {
        const user = response.data;
        commit("SET_USER_DATA", user);
      }
    },
  },
  modules: {},
});

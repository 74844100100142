<template>
  <div v-if="user == null">Loading...</div>
  <nav v-else>
    <v-app-bar app flat clipped-left outlined hide-overlay color="white">
      <v-menu rounded="lg" offset-y>
        <template v-slot:activator="{ attrs, on }">
          <div class="workspace-select" v-bind="attrs" v-on="on">
            <img
              src="@/assets/visum/icon.png"
              class="workspace-logo"
              alt="workspace-reversecontact"
            />

            <div style="display: inline-block">
              <div class="workspace-label">WORKSPACE</div>
              <div class="workspace-name">ScrapIn</div>
            </div>
          </div>
        </template>

      </v-menu>

      <v-app-bar-nav-icon
        class="grey--text"
        :hidden="drawer"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-spacer></v-spacer>

      <template v-if="showDrawer">
      <v-btn
        large
        router
        to="/pricing"
        class="white--text btn-upgrade"
        :style="
          'background-image: linear-gradient(to right, ' +
          $theme.secondary +
          ', ' +
          $theme.secondary2 +
          ');'
        "
      >
        <span>Upgrade</span>
      </v-btn>
      <div class="px-5 credits-container" v-if="user.quota.total < 99999999">
        <v-icon left :color="$theme.secondary"
          >mdi-lightning-bolt-outline</v-icon
        >
          <span class="credits-label">Credits</span>
          <v-progress-linear
            style="margin: 0px 16px; width: 100px; height: 6px"
            :value="(user.quota.credits / user.quota.total) * 100"
            :color="$theme.secondary"
            rounded
          ></v-progress-linear>
          <small class="font-weight-bold" :style="'color: ' + $theme.secondary"
            >{{ user.quota.credits }} contact{{
              user.quota.credits > 1 ? "s" : ""
            }}
          </small>
      </div>
      <div class="px-5 credits-container" v-else>
        <v-icon left :color="$theme.secondary"
          >mdi-lightning-bolt-outline</v-icon
        >
          <small class="font-weight-bold" :style="'color: ' + $theme.secondary">Unlimited plan</small>
      </div>

      <div v-if="user.quota.credits > (user.quota.total_rate_limit - user.quota.rate_limit)" class="px-5 credits-container">
        <v-icon left :color="$theme.secondary"
          >mdi-speedometer-medium</v-icon
        >
        <span class="credits-label">Daily limit</span>
        <small class="px-5 font-weight-bold" :style="'color: ' + $theme.secondary"
          >{{ user.quota.rate_limit }} / {{ user.quota.total_rate_limit }}</small
        >
      </div>
    </template>

    <template v-else>
      <v-btn to="/" depressed color="primary" outlined>Back to dashboard</v-btn>
    </template>

      <div class="user-infos">
        <span class="font-weight-bold">{{ user.firstName }}</span>
        <img
          v-on:click="redirectToProfile"
          class="avatar"
          src="@/assets/visum/visum-avatar.png"
        />
      </div>
    </v-app-bar>

    <v-navigation-drawer
      v-model="drawer"
      floating
      clipped
      app
      class="px-4 side-menu d-flex"
      color="fbfdfe"
      width="300"
      v-if="showDrawer"
    >
      <div class="mt-5" style="background-color: white">
        <v-list nav class="py-0" rounded>
          <v-list-item
            v-for="item in sections"
            :key="item.title"
            :color="$theme.secondary"
            :disabled="item.disabled"
            v-on:click="item.action"
            v-show="!item.bottom"
          >
            <v-list-item-avatar>
              <v-icon
                :color="
                  currentRouteName == item.route ? $theme.secondary : 'black'
                "
                v-text="item.icon"
              ></v-icon>
            </v-list-item-avatar>
            <v-list-item-content v-if="item.label !== false">
              <v-list-item-title style="padding-bottom: 8px"
                ><v-badge
                  :color="
                    currentRouteName == item.route ? $theme.secondary : 'black'
                  "
                  :content="item.label"
                >
                  {{ item.title }}</v-badge
                >
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-title
                :style="
                  'color:' + currentRouteName == item.route
                    ? '#F76B1C !important;'
                    : 'black'
                "
                >{{ item.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>

      <div class="mb-5 mt-auto" style="background-color: white">
        <v-list nav class="py-0" rounded>
          <v-list-item
            v-for="item in sections"
            :key="item.title"
            :color="$theme.secondary"
            :disabled="item.disabled"
            v-on:click="item.action"
            v-show="item.bottom"
          >
            <v-list-item-avatar>
              <v-icon
                :color="
                  currentRouteName == item.route ? $theme.secondary : 'black'
                "
                v-text="item.icon"
              ></v-icon>
            </v-list-item-avatar>
            <v-list-item-content v-if="item.label !== false">
              <v-list-item-title
                :class="
                  currentRouteName == item.route
                    ? $theme.secondary
                    : 'black-secondary'
                "
                ><v-badge
                  :color="
                    currentRouteName == item.route ? $theme.secondary : 'black'
                  "
                  :content="item.label"
                  >{{ item.title }}</v-badge
                >
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-content v-else>
              <v-list-item-title>{{ item.title }} </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import PaymentServices from "@/services/PaymentServices";
import { mapState } from "vuex";

export default {
  data: () => ({
    drawer: true,
    sections: [],
    snackbar: false,
    select: null,
  }),
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    showDrawer() {
      return !["Pricing"].includes(this.currentRouteName) &&
      !["PricingEnterprise"].includes(this.currentRouteName)
    },
    ...mapState({
      user: (state) => state.user,
    }),
  },
  created() {
    this.sections = [
      {
        icon: "mdi-card-search-outline",
        title: "Query Builder",
        route: "UniqueSearch",
        disabled: false,
        isActive: false,
        label: false,
        action: () => {
          this.$router.push({ name: "UniqueSearch" });
        },
        bottom: false,
      },
      {
        icon: "mdi-code-json",
        title: "API & Integrations",
        route: "Api",
        disabled: false,
        label: false,
        action: () => {
          this.$router.push({ name: "Api" });
        },
        bottom: false,
      },
      {
        icon: "mdi-book-open-blank-variant",
        title: "Documentations",
        route: "/blog",
        disabled: false,
        label: false,
        action: () => {
          window.open("https://docs.scrapin.io/", "_blank");
        },
        bottom: false,
      },
      {
        icon: "mdi-credit-card",
        title: "Billing",
        disabled: false,
        label: false,
        action: this.billing,
        bottom: true,
      },
      {
        icon: "mdi-logout",
        title: "Sign out",
        disabled: false,
        label: false,
        action: this.logout,
        bottom: true,
      },
    ];
  },
  async beforeCreate() {
    // Fetching fresh User Profile and update it in Store
    await this.$store.dispatch("profile");
  },
  mounted() {
    this.$root.$on("update_progress_bar", (credits) => {
      this.user.quota.credits = credits;
    });

    this.$root.$on("update_progress_bar_rate_limit", (rate_limit) => {
      this.user.quota.rate_limit = rate_limit;
    });
  },
  methods: {
    redirectToProfile() {
      this.$router.push({ name: "Profile" });
    },
    logout() {
      this.$store.dispatch("logout");
    },
    async billingPortal() {
      var response = await PaymentServices.billingPortal();
      if (response.data.success == true) {
        window.location.href = response.data.url;
      }
    },
    async billing() {
      if (
        this.user.stripe.subscriptionId != undefined &&
        this.user.stripe.customerId == ""
      )
        this.$router.push({ name: "Pricing" });
      else if (
        this.user.stripe.subscriptionId != undefined &&
        this.user.stripe.customerId != ""
      )
        await this.billingPortal();
    },
  },
};
</script>

<style>
.btn-upgrade {
  min-width: 200px !important;
  height: 46px !important;
  box-shadow: initial !important;
  border-radius: 6px !important;
  font-weight: 600 !important;
  color: white !important;
  font-size: 1rem !important;
}

.v-btn {
  letter-spacing: unset !important;
}

.credits-container {
  border-radius: 6px;
  border: 2px solid #e3e3e3;
  width: auto;
  height: 46px;
  margin: 0px 10px;
  display: flex;
  align-items: center;
}

.credits-container > small {
  font-size: 0.875em !important;
}

.credits-label {
  font-weight: 700 !important;
  font-size: 0.875em;
}

.user-infos {
  display: contents;
}

.user-infos > span {
  margin: 0px 0px 0px 10px;
}

.avatar {
  /* This image is 687 wide by 1024 tall, similar to your aspect ratio */
  /*background-image: url("http://i.stack.imgur.com/Dj7eP.jpg");*/

  /* make a square container */
  width: 40px;
  height: 40px;

  cursor: pointer;

  /* fill the container, preserving aspect ratio, and cropping to fit */
  background-size: cover;

  /* center the image vertically and horizontally */
  background-position: top center;

  margin: 0px 14px;

  /* round the edges to a circle with border radius 1/2 container size */
  border-radius: 50%;
}

.workspace-select {
  width: 266px;
  min-width: 266px;
  height: 46px;
  border: 1px solid var(--select-border);
  border-radius: 6px;
  border: 2px solid #e3e3e3;
  cursor: pointer;
  background-color: #fff;
  padding: 0 16px;
  align-items: center !important;
  display: flex;
}

.workspace-label {
  width: auto; /* 78px /
  height: auto; / 16px */
  overflow: visible;
  white-space: pre;
  font-weight: 500;
  font-style: normal;
  color: #a9b2bc;
  letter-spacing: 0px;
  line-height: 1.1;
  font-size: 0.75em;
}

.workspace-name {
  width: auto;
  overflow: visible;
  white-space: pre;
  font-weight: 600 !important;
  font-style: normal !important;
  color: #0d2144 !important;
  letter-spacing: unset;
  line-height: 1.1 !important;
  font-size: 1.125em !important;
}

.workspace-logo {
  width: auto;
  height: 30px;
  margin-right: 10px;
}

.v-toolbar__content {
  border-bottom: 2px solid #e3e3e3;
}

.side-menu {
  margin-top: 20px;
  max-height: calc(100% - 100px) !important;
  padding: 0 20px !important;
  background-color: #fbfdfe !important;
}

.v-navigation-drawer__content {
  max-height: calc(100% - 10px) !important;
  box-shadow: 0px 1px 2px 0px rgb(0 0 0 / 25%);
  border-radius: 10px;
  display: grid;
  background-color: white !important;
}

.v-list-item__avatar {
  margin-right: 12px !important;
}

.v-list-item__title {
  font-weight: 500;
}

.grey-secondary {
  color: #a9b2bc !important;
}
</style>

<template>
  <v-app>
    <NavBar />
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "@/components/Layouts/NavBar.vue";

export default {
  name: "App",
  components: {
    NavBar,
  },
  metaInfo() {
    return {
    };
  },
};
</script>

<style>
.v-application {
  background-color: #fbfcff !important;
}

.btn-visum {
  color: white !important;
  text-transform: none !important;
  height: 50px !important;
  font-size: 1em !important;
  font-weight: 600 !important;
}

.v-input__control {
  min-height: 42px !important;
  box-shadow: none !important;
}

.input-visum.v-input--is-focused > .v-input__control > .v-input__slot {
  border: 2px solid #3d62a7 !important;
}

.input-visum.v-input--is-dirty > .v-input__control > .v-input__slot {
  border: 2px solid #3d62a7 !important;
}

.input-visum .v-input__slot {
  border: 2px solid #e3e3e3;
  box-shadow: none !important;
  border-radius: 6px !important;
}
</style>

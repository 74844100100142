import http_common from '@/services/http_common'


export default {

  getAllFiles () {
    return http_common().get('files')
  },

  deleteFile (params) {
    return http_common().put('files/delete/' + params.id)
  },

  createFile (params) {
    return http_common().post('files/create', params)
  },

  uploadWebhook (params) {
    return http_common().post('files/upload/webhook', params)
  },
}

<template>
  <div v-if="user == null"></div>
  <v-container v-else fluid class="d-flex flex-column justify-center align-center" style="height: 100vh;">
    <div v-if="!user">Loading...</div>
    <div v-else>
      <button class="btn btn-sm btn-outline m-2" v-on:click="integrationApp.open()">
        Configure CRM Integration
      </button>
    </div>
  </v-container>
</template>

<script>
import AuthServices from "@/services/AuthServices";
import { IntegrationAppClient } from "@integration-app/sdk";

export default {
  data: () => ({
    user: false
  }),
  methods: {
    async displayIntegration() {
      var tokenResp = await AuthServices.getIntegrationToken()
      console.log(tokenResp);
      this.integrationApp = new IntegrationAppClient({
        token: tokenResp.data
      });
      this.user = async () => await integrationApp.self.get();
    },
  },
  created() {
    this.displayIntegration();
  },
};
</script>

<style>

</style>

<template>
  <div v-if="user == null"></div>
  <div v-else class="py-5 px-4 ma-0">
    <v-dialog
      v-model="dialog"
      width="500"
    >
      <v-card>
        <v-card-title class="headline ">
          🔑 My API Key
        </v-card-title>

        <v-card-text class="text-center">
          <v-col cols="12">
            <span class="text-subtitle-1 error--text"><span class="font-weight-black"> Warning: </span> An account can only have one API key, revoke active key will generate new one. Only share this key with trusted services.</span>
          </v-col>
          <v-text-field
            solo
            disabled
            :value="secretKey.value"
          ></v-text-field>
         </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            :color="$theme.secondary"
            class="white--text"
            text
            @click="dialogRevokeAPIKey"
          >
            Revoke this API Key
          </v-btn>
          <v-btn
            :color="$theme.primary"
            class="white--text"
            text
            @click="dialog = false"
          >
            Back
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
        <v-card class="search-card text-center">
          <v-row>
            <v-col cols="12">
              <v-card-title>Your API Key 🔑</v-card-title>
              <p>Your API key allows you to connect all your external services.</p>
            </v-col>
            <v-col cols="6" offset="3" class="px-12">
              <v-btn
              @click="generateAPIKey()"
            block
            large
            class="white--text mb-3"
            depressed
            :color="$theme.secondary"
          >
            <span>Get my API key </span>
          </v-btn>
            </v-col>
          </v-row>
          <v-row align="center" justify="space-around">
            <v-btn href="https://docs.scrapin.io/" target="_blank" text>
              View API documentation
              <v-icon
                right
                dark
              >
                mdi-open-in-new
              </v-icon>
            </v-btn>
          </v-row>
        </v-card>
  </div>
</template>

<script>
import AuthServices from "@/services/AuthServices";
import Swal from "sweetalert2";
import { mapState } from "vuex";
export default {
  data() {
    return {
      dialog:false,
      secretKey: {
        newOne: false,
        value: ""
      }
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },
  methods: {
    async generateAPIKey() {
      this.dialog = true
      var response = await AuthServices.getApiKey();
      if (response.data.success) {
        this.secretKey.value = response.data.secretKey;
        this.secretKey.newOne = true;
      }
    },
    dialogRevokeAPIKey() {
      Swal.fire({
        title: 'Are you sure?',
        text: "After generating a new key, services using your current key will no longer be able to connect!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, revoke my key!'
      }).then((result) => {
        if (result.isConfirmed) {
          this.revokeAPIKey()
        }
      })
    },
    async revokeAPIKey() {
      var response = await AuthServices.revokeApiKey();
      if (response.data.success) {
        this.secretKey.value = response.data.secretKey;
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: `New API key generated`,
          showConfirmButton: false,
          timer: 3000
        });
      }
    },
  }
};
</script>

<style>
.search-card {
  padding: 14px 24px;
  margin: 0 0 20px 0 !important;
  width: 100%;
  border-radius: 8px !important;
}
</style>

<template>
  <div v-if="user == null"></div>
  <div v-else class="py-5 px-4 ma-0">
    <p>Upload a .csv, .xlsx or .xls file</p>
    <div class="d-flex justify-center">
      <v-card class="search-card">
        <div style="display:flex;margin-bottom:10px;">
          <usecsv-button
            :importerKey="licenseKey"
            :user="{ userId: user._id }"
            :metadata="{ email: user.email }"
            :onData="onData"
            id="csvButton"
            ref="csvButton"
            >Import Data</usecsv-button
          >
          <div style="margin-left:14px;">
          The process of each file cannot be stopped once started as the data is anonymized for data security and GDPR compliant reasons. So please be careful with duplicate files 🚨
          </div>
        </div>
        <v-data-table :headers="headers" :items="array">
          <template v-slot:top>
            <v-toolbar flat>
              <v-card-title>My files</v-card-title>
              <v-spacer></v-spacer>
            </v-toolbar>
          </template>
          <template v-slot:item.status="{ item }">
            <v-progress-linear
              :value="(item.jobCompleted / item.jobNb) * 100"
              :color="
                (item.jobCompleted / item.jobNb) * 100 < 100
                  ? $theme.secondary2
                  : $theme.secondary
              "
              height="25"
            >
              <template v-slot:default="{ value }">
                <strong>{{ Math.ceil(value) }}%</strong>
              </template>
            </v-progress-linear>
          </template>
          <template v-slot:item.actions="{ item }">
            <a
              style="text-decoration: none"
              class="mr-6"
              @click="openCsv(item)"
              target="_blank"
            >
              <v-icon> mdi-download </v-icon>
            </a>
            <!--<v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>-->
          </template>
          <template v-slot:no-data>
            <!-- TODO: Display a message or action -->
          </template>
        </v-data-table>
      </v-card>
    </div>
  </div>
</template>

<script>
import FilesServices from "@/services/FilesServices";
import { CSVBoxButton } from "@csvbox/vuejs";
import Swal from "sweetalert2";
import { mapState } from "vuex";

export default {
  components: {
    CSVBoxButton,
  },
  data: () => ({
    licenseKey: process.env.VUE_APP_CSV_BOX_SHEET_KEY,
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: "File name",
        align: "start",
        sortable: false,
        value: "name",
      },
      { text: "Number of contacts", value: "jobNb" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    array: [],
    editedIndex: -1,
    editedItem: {
      name: "",
      jobNb: 0,
      jobCompleted: 0,
    },
    defaultItem: {
      name: "",
      jobNb: 0,
      jobCompleted: 0,
    },
  }),

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  mounted() {
    this.getAllFiles();
  },

  computed: {
    ...mapState({
      user: (state) => state.user,
    }),
  },

  methods: {
    onData: async function (data) {
      console.log(data)
      try {
        if (this.user.quota.credits < data.rows.length)
          throw new Error("INSUFFICIENT_FUNDS");
        else if(this.user.quota.total_rate_limit - this.user.quota.rate_limit < data.rows.length)
          throw new Error("INSUFFICIENT_RATE_LIMIT");

        const createFileResponse = await this.createFile({ ...data });
        if (!createFileResponse) throw new Error("UPLOAD_ERROR");

        const uploadWebhookResponse = await this.uploadWebhook({ ...data });
        if (!uploadWebhookResponse) throw new Error("UPLOAD_ERROR");

        document.getElementsByClassName("usecsv_container")[0].remove();
        this.$root.$emit(
          "update_progress_bar",
          this.user.quota.credits - data.rows.length
        );

        Swal.fire({
          icon: "success",
          title: "Your file is in good hands !",
          text: "You will receive an email when the processing of your file is finished.",
          showConfirmButton: false,
          timer: 3000,
        });
      } catch (error) {
        document.getElementsByClassName("usecsv_container")[0].remove();

        if (error.message == "INSUFFICIENT_FUNDS") {
          Swal.fire({
            icon: "warning",
            title: "You have to upgrade !",
            text: "Sorry, you don't have enough credits.",
          });
          this.$router.push({ name: "Pricing" })
        }
        else if(error.message == "INSUFFICIENT_RATE_LIMIT") {
          Swal.fire({
            icon: "warning",
            title: "You have reached your daily rate limit !",
            text: "That's it for today! But you can choose to upgrade your plan to get more credits and rate limit!",
          });
          this.$router.push({ name: "Pricing" })
        } else {
          Swal.fire({
            icon: "error",
            title: "An error has occurred !",
            text: "Please contact support.",
          });
        }
      }
    },
    async getAllFiles() {
      var response = await FilesServices.getAllFiles();
      if (response.data.success) {
        this.array = response.data.files;
      }
    },

    async createFile(fileInfos) {
      var response = await FilesServices.createFile(fileInfos);
      if (response.status == 200) {
        this.array.push(response.data.file);
        return true;
      } else return false;
    },

    async uploadWebhook(fileInfos) {
      var response = await FilesServices.uploadWebhook(fileInfos);
      if (response.status == 200) {
        return true;
      } else return false;
    },

    openCsv(item) {
      window.open(process.env.VUE_APP_API_URL + "files/" + item.path, "_blank");
    },
  },
};
</script>

<style>
.search-card {
  padding: 14px 24px;
  margin: 0 0 20px 0 !important;
  width: 100%;
  border-radius: 8px !important;
}
#usecsv-button {
  align-items: center;
    border-radius: 4px;
    display: inline-flex;
    flex: 0 0 auto;
    justify-content: center;
    outline: 0;
    position: relative;
    text-decoration: none;
    transition-duration: 0.28s;
    transition-property: box-shadow, transform, opacity;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
    white-space: nowrap;
    background-color: rgb(33, 111, 237) !important;
    border-color: rgb(33, 111, 237) !important;
    color: white !important;
    text-transform: none !important;
    height: 50px !important;
    width: 200px !important;
    font-size: 1em !important;
    font-weight: 600 !important;
}
#usecsv-button:hover {
  background-color: rgb(76, 136, 233) !important;
  border-color: rgb(76, 136, 233) !important;
}
</style>

/* CONFIG */
import Vue from "vue";
import VueRouter from "vue-router";
import Main from "../Main.vue";
/* GUEST */
import Auth from "../views/Auth.vue";
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
/* USER */
import Api from "../views/Api.vue";
import Pricing from "../views/Pricing.vue";
import PricingEnterprise from "../views/PricingEnterprise.vue";
import Integrations from "../views/PrivateIntegration.vue";
import Profile from "../views/Profile.vue";

import Files from "../views/Files.vue";
import NewFile from "../views/NewFile.vue";
import UniqueSearch from "../views/UniqueSearch.vue";

Vue.use(VueRouter);

const routes = [
  /* GUEST ROUTE */
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/user/auth",
    name: "Auth",
    component: Auth,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/",
    redirect: "/search",
    component: Main,
    children: [
      /* USER ROUTE */
      {
        path: "/profile",
        name: "Profile",
        component: Profile,
        meta: { requiresAuth: true },
      },
      {
        path: "/api",
        name: "Api",
        component: Api,
        meta: { requiresAuth: true },
      },
      {
        path: "/integrations",
        name: "Integrations",
        component: Integrations,
        meta: { requiresAuth: true },
      },
      {
        path: "/pricing",
        name: "Pricing",
        component: Pricing,
        meta: { requiresAuth: true },
      },
      {
        path: "/pricing-enterprise",
        name: "PricingEnterprise",
        component: PricingEnterprise,
        meta: { requiresAuth: true },
      },
      /* REVERSECONTACT ROUTE */
      {
        path: "/search",
        name: "UniqueSearch",
        component: UniqueSearch,
        meta: { requiresAuth: true },
      },
      {
        path: "/files",
        name: "Files",
        component: Files,
        meta: { requiresAuth: true },
      },
      {
        path: "/upload",
        name: "NewFile",
        component: NewFile,
        meta: { requiresAuth: true },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");
  if (to.matched.some((record) => record.meta.requiresAuth) && !loggedIn) {
    next("/login");
  }
  next();
});

export default router;

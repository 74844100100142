<template>
  <div class="py-5 px-4 ma-0">
    <h3>Your profile</h3>
    <v-row>
      <v-col cols="6">
        <v-card class="search-card">
          <v-card-title>Personal information</v-card-title>
          <div>
            <div
              v-show="user.photoUrl != undefined && user.photoUrl.length > 0"
              class="my-4"
            >
              <v-avatar size="128">
                <img :src="user.photoUrl" />
              </v-avatar>
            </div>
            <div class="d-flex flex-column">
              <span class="caption">Full Name</span>
              <span>{{ user.firstName }} {{ user.lastName }}</span>
            </div>
            <div class="d-flex flex-column">
              <span class="caption">Company</span>
              <span>{{ user.company }}</span>
            </div>
            <div class="d-flex flex-column">
              <span class="caption">Created date</span>
              <span>{{ user.created_at }}</span>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="search-card">
          <v-card-title>Contact information</v-card-title>
          <div>
            <div class="d-flex flex-column">
              <span class="caption">Email</span>
              <span>{{ user.email }}</span>
            </div>
            <div class="d-flex flex-column">
              <span class="caption">Phone</span>
              <span>{{ user.phone }}</span>
            </div>
            <div class="d-flex flex-column">
              <span class="caption">Website</span>
              <span>{{ user.website }}</span>
            </div>
          </div>
        </v-card>
        <v-card class="search-card">
          <v-card-title>Plan information</v-card-title>
          <div>
            <div class="d-flex flex-column">
              <span class="caption">Credit remaining</span>
              <span>{{ user.quota.credits }}</span>
            </div>
            <div class="d-flex flex-column">
              <span class="caption">Total</span>
              <span>{{ user.quota.total }}</span>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AuthServices from "@/services/AuthServices";

export default {
  data: () => ({
    user: {
      firstName: "",
      photoUrl: "",
      is_active: false,
      quota: {
        total: 0,
        credits: 0,
      },
    },
    drawer: true,
    sections: [],
    snackbar: false,
  }),
  methods: {
    getProfile() {
      AuthServices.getProfile().then((response) => {
        this.user = response.data.user;
      });
    },
  },
  created() {
    this.getProfile();
  },
};
</script>

<style>
.search-card {
  padding: 14px 24px;
  margin: 0 0 20px 0 !important;
  width: 100%;
  border-radius: 8px !important;
}
</style>

<template>
  <div v-if="user == null"></div>
  <v-container v-else>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step :complete="e1 > 1" step="1">Upload your file</v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="2">Launching enrichment</v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <h2>
            Select a .csv file on your computer
          </h2>
          <v-form ref="files">
            <div class="ma-2">
              <span>
                What is the name of the column where the email addresses are present in your file?
                <strong>By default we use "email".</strong>
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      target="_blank"
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                      text
                    >
                      <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                  </template>
                  <div>
                    <div class="d-flex justify-center">
                      <v-img src="@/assets/visum/csv_ex_sheet.png" max-width="500"></v-img>
                    </div>
                  </div>
                </v-tooltip>
              </span>
            </div>
            <div class="ma-2">
              <v-text-field
                solo
                :rules="emailRules"
                v-model="colIndex"
                prepend-icon="mdi-table-column-width"
              ></v-text-field>
            </div>
            <div class="ma-2">
              <span>
                What is the separator in your csv file?
                <strong>By default we use the comma ","</strong>
              </span>
              <v-text-field
                solo
                v-model="separator"
                placeholder="separator"
                prepend-icon="mdi-table-large"
              ></v-text-field>
            </div>
            <div class="ma-2">
              <v-file-input
                show-size
                solo
                accept=".csv"
                label="Import your .csv here"
                @change="selectFile"
                :rules="ruleFiles"
              ></v-file-input>
            </div>
            <v-btn
              :color="$theme.secondary"
              class="white--text"
              @click="countContact"
            >Next</v-btn>
          </v-form>
        </v-stepper-content>

        <v-stepper-content step="2">

          <div class="text-center mt-2">
            <h2>{{emailNb}} contacts found. For this list enrichment, you will pay ${{ (emailNb * 0.10).toFixed(2) }}</h2>
          </div>
          <v-btn :color="$theme.secondary" @click="run" class="white--text">Buy Data: ${{ (emailNb * 0.10).toFixed(2) }}</v-btn>

          <v-btn text @click="e1 = 1">Cancel</v-btn>

          <div class="text-center mt-2">
            <span
              class="text-caption"
            >For each contact, $0,10 will be debited after the search is launched.</span>
          </div>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <div></div>
  </v-container>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      emailNb: 0,
      e1: 1,
      file: null,
      overlay: false,
      colIndex: "email",
      separator: ',',
      emailRules: [
          v =>
            (!!v && !(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v))) ||
            "Oops! It seems that you have entered an email address"
        ],
      ruleFiles: [
        value =>
          !value ||
          value.size < 20000000 ||
          "CSV file size should be less than 10 MB!"
      ]
    };
  },
  methods: {
    selectFile(file) {
      this.file = file;
    },
    countContact() {
      if (this.$refs.files.validate && this.file != null) {
        this.overlay = true;
        var bodyFormData = new FormData();
        bodyFormData.append("file", this.file);
        bodyFormData.append("colIndex", this.colIndex);
        bodyFormData.append("separator", this.separator);
        let config = {
          baseURL: process.env.VUE_APP_API_URL,
          withCredentials: true,
          headers: {
            //Authorization: localStorage.getItem("SID"),
            "Content-Type": "multipart/form-data"
          }
        };
        var _this = this;
        axios
          .post(
            `${process.env.VUE_APP_API_URL}enrichment/upload/count`,
            bodyFormData,
            config
          )
          .then(function(response) {
            if (response.data.success == true) {
              _this.emailNb = response.data.emailNb
              _this.overlay = false;
              _this.e1 = 2;
            } else {
              Swal.fire({
                icon: "error",
                title: response.data.title,
                text: response.data.msg
              });
              _this.overlay = false;
            }
          })
          .catch(function(response) {
            console.log(response);
            Swal.fire({
              icon: "error",
              title: "An error has occurred 🚒",
              text:
                "Are you sure you uploaded a CSV file? You can find a template at step 1 of the upload process."
            });
            _this.overlay = false;
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "An error has occurred 🚒",
          text:
            "Are you sure you uploaded a CSV file? You can find a template at step 1 of the upload process."
        });
        this.e1 = 1;
      }
    },
    run() {
      if (this.file != null) {
        this.overlay = true;
        var bodyFormData = new FormData();
        bodyFormData.append("file", this.file);
        bodyFormData.append("colIndex", this.colIndex);
        bodyFormData.append("separator", this.separator);
        let config = {
          baseURL: process.env.VUE_APP_API_URL,
          withCredentials: true,
          headers: {
            //Authorization: localStorage.getItem("SID"),
            "Content-Type": "multipart/form-data"
          }
        };
        var _this = this;
        axios
          .post(
            `${process.env.VUE_APP_API_URL}enrichment/upload/checkout`,
            bodyFormData,
            config
          )
          .then(function(response) {
            if (response.data.success == true) {
              window.location.href = response.data.url
            } else {
              Swal.fire({
                icon: "error",
                title: response.data.title,
                text: response.data.msg
              });
              _this.overlay = false;
              _this.e1 = 1;
            }
          })
          .catch(function(response) {
            console.log(response);
            Swal.fire({
              icon: "error",
              title: "An error has occurred 🚒",
              text:
                "Are you sure you uploaded a CSV file? You can find a template at step 1 of the upload process."
            });
            _this.overlay = false;
            _this.e1 = 1;
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "An error has occurred 🚒",
          text:
            "Are you sure you uploaded a CSV file? You can find a template at step 1 of the upload process."
        });
        this.e1 = 1;
      }
    }
  }
};
</script>

import http_common from '@/services/http_common'


export default {

  checkout (params) {
    return http_common().post('payments/checkout', params)
  },

  billingPortal (params) {
    return http_common().post('payments/billing', params)
  },

  downgrade (params) {
    return http_common().post('payments/downgrade', params)
  },

}

import axios from "axios";

export default () => {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    withCredentials: true,
  });

  return axiosInstance;
};

<template>
  <div class="md:t-flex md:t-h-screen">
    <div class="md:t-w-1/2 t-p-6 md:t-p-10">
      <img src="../assets//visum/logo-full.png" class="t-w-[200px]" />

      <div
        class="t-flex t-justify-center t-items-center t-h-full t-mt-10 md:t-mt-0"
      >
        <div class="t-space-y-10 md:t-w-[380px] t-box-content">
          <lottie-animation
            v-if="!loading"
            :animationData="animationData"
            class="t-w-[56px]"
          />
          <lottie-animation
            :loop="true"
            :autoPlay="false"
            ref="loading"
            :style="displayLoading"
            :animationData="animationData"
            class="t-w-[56px]"
          />
          <h1
            class="t-text-[27px] md:t-text-[32px] t-font-bold t-text-gray-900"
          >
            Sign up for free
          </h1>

          <form v-on:submit.prevent="onSubmit">
            <div class="t-mb-10">
              <label for="email">Work email</label>
              <input
                v-model="email"
                id="email"
                type="email"
                placeholder="email@domain.com"
                class="t-mt-2"
                autofocus
              />
            </div>

            <div class="t-flex t-items-center t-mb-4">
              <input id="tos" type="checkbox" v-model="cgu" />
              <label
                for="tos"
                class="t-ml-2 t-text-xs [line-height:22px] t-font-light t-text-gray-500"
              >
                I agree to the
                <a
                  href="https://www.scrapin.io/terms-and-condition"
                  target="_blank"
                  >Terms of Service</a
                >.
              </label>
            </div>
            <vue-hcaptcha
              size="invisible"
              ref="hcaptcha"
              :sitekey="hcaptcha_sitekey"
              @verify="onVerify"
              @expired="onExpire"
              @challengeExpired="onChallengeExpire"
              @error="onError"
            ></vue-hcaptcha>
            <button
              class="t-w-full"
              :disabled="isDisabled"
              @submit.prevent="onSubmit"
            >
              Get started
            </button>
          </form>

          <div class="t-flex t-justify-between">
            <span class="t-text-gray-500 t-font-medium">Have an account?</span>
            <router-link to="/login">Log In</router-link>
          </div>
        </div>
      </div>
      <footer style="visibility: hidden">
        <a href="https://www.scrapin.io/">Return to homepage</a>
      </footer>
    </div>
    <div
      class="md:t-w-1/2 t-h-full t-flex t-justify-center t-items-center t-px-6 t-py-10 md:t-px-10"
      style="
        background: linear-gradient(
          162.59deg,
          rgba(104, 81, 255, 0.18) 5.64%,
          rgba(144, 222, 240, 0.18) 97.85%
        );
      "
    >
      <div class="md:t-w-[332px]">
        <h4 class="t-font-semibold t-text-lg t-mb-8">
          Turn any Email into Business Opportunity
        </h4>
        <template
          v-for="text in [
            'Optimize information gathering',
            'Automate the updating of your contacts',
            'Don’t miss out on an opportunity',
            'Rely on a tool that’s GDPR compliant',
          ]"
        >
          <div :key="text" class="t-flex t-gap-[20px] t-items-center t-mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="14"
              viewBox="0 0 16 14"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.1549 1.89347C15.3475 2.08099 15.3574 2.3873 15.1772 2.58684L5.92017 12.839C5.72162 13.0589 5.3765 13.0589 5.17796 12.839L0.822757 8.01564C0.64258 7.8161 0.652453 7.50979 0.845108 7.32227L1.64787 6.54087C1.85101 6.34314 2.17774 6.35367 2.36773 6.56408L5.54906 10.0874L13.6323 1.13528C13.8223 0.924871 14.149 0.914339 14.3521 1.11207L15.1549 1.89347Z"
                fill="#6851FF"
                stroke="#6851FF"
              />
            </svg>

            <span class="t-text-lg">{{ text }}</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import LottieAnimation from "lottie-web-vue";
import Swal from "sweetalert2";
import animationData from "../assets/lotties/ScrapinAnimation.json";

export default {
  components: { LottieAnimation, VueHcaptcha },
  data() {
    return {
      hcaptcha_sitekey: process.env.VUE_APP_HCAPTCHA_PUBLIC_KEY,
      loading: false,
      animationData,
      email: "",
      cgu: false,
      verified: false,
      expired: false,
      token: null,
      error: null,
    };
  },
  mounted() {
    if (localStorage.getItem("user")) {
      this.$router.push({ name: "UniqueSearch" });
    }
  },
  methods: {
    onExpire() {
      this.verified = false;
      this.token = null;
      this.expired = true;
      console.log("Challenge expired.");
    },
    onChallengeExpire() {
      this.verified = false;
      this.token = null;
      this.expired = true;
      console.log("Challenge expired.");
    },
    onError(err) {
      this.token = null;
      this.error = err;
      Swal.fire({
        icon: "error",
        title: "An error occurred",
        text: "Please try again later.",
      });
    },
    onSubmit() {
      this.$refs.hcaptcha.execute();
    },
    onVerify(token) {
      this.verified = true;
      this.register(token);
    },
    register(token) {
      this.loading = true;
      this.$refs.loading.play();

      this.$store
        .dispatch("getOTP", { email: this.email, captcha: token })
        .then(() => {
          this.loading = false;
          this.$refs.loading.stop();
          Swal.fire({
            icon: "success",
            title: "An email has been sent to you",
            text: "Check your inbox to log in!",
            timer: 4000,
          });
        })
        .catch((error) => {
          this.loading = false;
          this.$refs.loading.stop();
          Swal.fire({
            icon: "error",
            title: "An error occurred",
            text: "Please try again later.",
          });
        });
    },
  },
  computed: {
    displayLoading: function () {
      if (this.loading) return "display: block !important;";
      else return "display: none !important;";
    },
    isDisabled: function () {
      return (
        !this.cgu ||
        !/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          this.email
        ) ||
        this.loading
      );
    },
  },
};
</script>

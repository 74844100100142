import http_common from '@/services/http_common'
const handle = (promise) => {
	return promise
		.then(data => ([data, undefined]))
		.catch(error => Promise.resolve([undefined, error]));
}

export default {
  async emailLookup(query) {
    return handle(http_common().get('/enrichment/app?mail=' + query.email + '&firstName=' + query.firstName + '&lastName=' + query.lastName + '&companyDomain=' + query.companyDomain))
  },
	async domainLookup(query) {
    return handle(http_common().get('/enrichment/company/domain?domain=' + query.companyDomain + '&apikey=' + query.apikey))
  },
	async ExtractPersonDataProfile(query) {
    return handle(http_common().get('/enrichment/profile?linkedInUrl=' + query.linkedInUrl + '&apikey=' + query.apikey))
  },
	async ExtractCompanyDataProfile(query) {
    return handle(http_common().get('/enrichment/company?linkedInUrl=' + query.linkedInUrl + '&apikey=' + query.apikey))
  },
}

import http_common from "@/services/http_common";

export default {
  register(params) {
    return http_common().post("/users/register", params);
  },

  getOTP(params) {
    return http_common().post("app/public/user/auth", params);
  },

  auth(params) {
    return http_common().post("app/public/user/auth/confirm", params);
  },

  logout() {
    return http_common().post("/users/logout");
  },

  getApiKey() {
    return http_common().get("users/get-api-key");
  },

  revokeApiKey() {
    return http_common().post("users/revoke-api-key");
  },

  getProfile() {
    return http_common().get("/users/profile");
  },

  saveProfile(params) {
    return http_common().post("/users/profile/save", params);
  },

  getIntegrationToken(params) {
    return http_common().post("/users/integration-token");
  },
};
